/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "us-east-1",
    "aws_appsync_graphqlEndpoint": "https://64tgsrnz3vajffeehkhw6sriha.appsync-api.us-east-1.amazonaws.com/graphql",
    "aws_appsync_region": "us-east-1",
    "aws_appsync_authenticationType": "API_KEY",
    "aws_appsync_apiKey": "da2-efkjkuu4fjdvbnqduh3cbetd6u",
    "aws_cognito_identity_pool_id": "us-east-1:8962e35f-9e0c-4860-9c98-95e87eb58aba",
    "aws_cognito_region": "us-east-1",
    "aws_user_pools_id": "us-east-1_IeTPy0Plu",
    "aws_user_pools_web_client_id": "3uteifr4bibu7rkfo9l71j9nk8",
    "oauth": {},
    "aws_cognito_username_attributes": [],
    "aws_cognito_social_providers": [],
    "aws_cognito_signup_attributes": [
        "EMAIL"
    ],
    "aws_cognito_mfa_configuration": "OFF",
    "aws_cognito_mfa_types": [
        "SMS"
    ],
    "aws_cognito_password_protection_settings": {
        "passwordPolicyMinLength": 8,
        "passwordPolicyCharacters": []
    },
    "aws_cognito_verification_mechanisms": [
        "EMAIL"
    ],
    "geo": {
        "amazon_location_service": {
            "region": "us-east-1",
            "maps": {
                "items": {
                    "map0db5cfba-staging": {
                        "style": "VectorEsriStreets"
                    }
                },
                "default": "map0db5cfba-staging"
            },
            "search_indices": {
                "items": [
                    "placeindex9106987c-staging"
                ],
                "default": "placeindex9106987c-staging"
            }
        }
    }
};


export default awsmobile;
